<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto nav-workZone">
      <b-nav-item>
        <Locale />
      </b-nav-item>
      <b-nav-item
        v-if="$store.state.project.selectedProject"
        v-b-tooltip.hover="'Chat'"
        @click="pushChatPage"
      >
        <feather-icon
          icon="MessageCircleIcon"
          size="21"
        />
      </b-nav-item>
      <b-nav-item>
        <notification-dropdown v-if="SELECTED_PROJECT" />
      </b-nav-item>
      <b-nav-item-dropdown
        id="tour-user-dropdown"
        ref="navDropDown"
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
        :class="showDropdown ? 'show' : ''"
      >
        <template #button-content>
          <div
            id="user-menu-btn"
            class="d-flex"
          >
            <div class="d-sm-flex d-none user-nav">
              <p class="user-name font-weight-bolder mb-0">
                {{ name }}
              </p>
              <div
                v-if="SELECTED_PROJECT && SELECTED_COMPANY"
                class="text-right"
              >
                <span
                  class="user-status d-flex"
                >
                  <span v-b-tooltip.hover="SELECTED_PROJECT.length > 25 ? SELECTED_PROJECT : ''">
                    {{ TRUNCATE_TEXT(SELECTED_PROJECT, 25) }}
                  </span>
                  &nbsp;/&nbsp;
                  <span v-b-tooltip.hover="SELECTED_COMPANY.length > 25 ? SELECTED_COMPANY : ''">
                    {{ TRUNCATE_TEXT(SELECTED_COMPANY, 25) }}
                  </span>
                </span>
                <span class="user-status">{{ isAdmin ? 'Administrator' : role }}</span>
              </div>
            </div>
            <b-avatar
              size="40"
              variant="light-primary"
              badge
              :src="getAvatar(avatar, 1)"
              class="badge-minimal"
              badge-variant="success"
            />
          </div>
        </template>

        <b-dropdown-item
          v-if="SELECTED_PROJECT"
          link-class="d-flex align-items-center"
          :to="{ name: 'pages-profile'}"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>{{ $t('Profile') }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="SELECTED_PROJECT"
          id="tour-change-project"
          link-class="d-flex align-items-center"
          @click="changeProject"
        >
          <feather-icon
            size="16"
            icon="BriefcaseIcon"
            class="mr-50"
          />
          <span class="text-truncate">{{ $t('Change Project') }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="SELECTED_PROJECT"
          id="project-tour-1"
          link-class="d-flex align-items-center"
          :to="{ name: 'user-general-settings' }"
        >
          <feather-icon
            size="16"
            icon="SettingsIcon"
            class="mr-50"
          />
          <span class="text-truncate">{{ $t('General Settings') }}</span>
        </b-dropdown-item>

        <b-dropdown-divider />
        <b-dropdown-item
          @click="visitTour"
        >
          <i class="fa fa-chalkboard-teacher mr-1" />
          <span>{{ $t('Visit Tour') }}</span>
        </b-dropdown-item>

        <b-dropdown-divider v-if="SELECTED_PROJECT" />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t('Logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

    <!-- Call Info Modal -->
    <call-info-modal
      v-if="showCallModal"
      :caller="caller"
    />

    <!-- Push Notification -->
    <tour-and-push-notification-popup />
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, VBTooltip, BNavItem,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import useJwt from '@/auth/jwt/useJwt'
import { checkPermission, projectSettings } from '@/utils/permissions'
import useApollo from '@/plugins/graphql/useApollo'
import EventBus from '@/event-bus'
import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
import { unsubscribeToken } from '@/utils/fcm'
import { TOPICS, mapPermissionSlug } from '@/const/common'
import { ChatEvents } from 'workzone-chat-sdk'
import CallInfoModal from '@/chat/components/CallInfoModal.vue'
import { getUserData } from '@/auth/utils'
import ringtone from '@/chat/components/audio/ringtone.mp3'
import message from '@/chat/components/audio/message.mp3'
import delivered from '@/chat/components/audio/delivered.mp3'
import react from '@/chat/components/audio/delivered.mp3'
import { continueTour } from '@/views/tour/tour'
import Locale from './Locale.vue'
import TourAndPushNotificationPopup from './TourAndPushNotificationPopup.vue'
import checkUserInfoStatus from './userInfoStatus'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BNavItem,
    // BOverlay,
    NotificationDropdown,
    // Navbar Components
    DarkToggler,
    Locale,
    CallInfoModal,
    TourAndPushNotificationPopup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      continueTour,
      self: getUserData(),
      name: '',
      avatar: null,
      isAdmin: false,
      isProcessing: false,
      showDropdown: false,
      notifications: [],
      permissions: ['timezones.update', 'permissions.manage', 'productivity.update', 'company.edit', 'leads.create', 'leads.close', 'leads.follow', 'cms.update', 'project.manageusers', 'campaigns.create', 'campaigns.edit'],
      showCallModal: false,
      caller: null,
      ringTone: null,
      messageTone: null,
      deliveredTone: null,
      reactTone: null,
      roomUid: null,
      callEndedBySelf: false,
      callDeclinedBy: [],
      ongoingCall: null,
    }
  },
  computed: {
    SELECTED_PROJECT() {
      return this.$store.state.project?.project?.name
    },
    SELECTED_COMPANY() {
      return this.$store.state.project?.company?.name
    },
    PROJECT_USERS() {
      return this.$store.state.project?.projectUsers
    },
    role() {
      return this.$store.state.project?.userRole
    },
    peers() {
      return Object.values(this.state.peers)
    },
    rooms() {
      return this.$store.state.chat?.rooms
    },
    initialChat() {
      return this.$store.state.chat?.initialChat
    },
    unseenMsgCounts() {
      return this.$store.state.chat?.unseenMsgCounts
    },
    PROJECT_CONTACTS() {
      return [
        ...this.$store.state.project?.projectUsers,
        ...this.$store.state.project?.projectGroups,
      ]
    },
  },
  watch: {
    SELECTED_PROJECT(val) {
      if (val) {
        this.getProjectUsers()
        this.$nextTick(() => this.$chatService.joinRoom(this.self.uuid))
      }
    },
    PROJECT_USERS(val) {
      if (val.length) {
        this.rooms.forEach(room => {
          if (room.participants.length) this.setRoomUidForPeer(room, 'room-joined')
          this.$nextTick(() => {
            this.$store.dispatch('chat/removeRoom', room)
          })
        })
      }
    },
    PROJECT_CONTACTS(val) {
      if (val.length && this.ongoingCall) {
        const { mediaState, roomUid } = this.ongoingCall
        this.handleOngoingCall(mediaState, roomUid)
      }
    },
    rooms(val) {
      if (!val.length) {
        this.initialChat.forEach(chat => {
          chat.messages.forEach(chatMessage => {
            if (chatMessage.senderUid !== this.self.uuid && !chatMessage.deliveries.length) {
              this.$chatService.broadcastMessageReceivedEvent(chat.roomUid, chatMessage.messageUid)
            }
          })
        })
        this.$store.dispatch('chat/setInitialChatLoad', this.initialChat)
        if (this.unseenMsgCounts.length) {
          this.$store.dispatch('chat/setUnseenMsgCount', this.unseenMsgCounts)
        }
      }
    },
  },
  created() {
    checkUserInfoStatus(this.self.uuid)
    this.fetchUserDetails()
    // Update the state when the redux state changes
    window.STORE.subscribe(() => {
      this.state = window.STORE.getState()
    })
    // eslint-disable-next-line no-restricted-globals
    onload = () => {
      this.refetchPermission()
      this.$store.dispatch('project/STORE_ALL_PERMISSIONS', this.SELECTED_PROJECT)
    }

    // FCM Events
    EventBus.$on('updated-avatar', avatar => { this.avatar = avatar })
    EventBus.$on('profile-updated', user => {
      this.fetchUserDetails(user)
    })
    EventBus.$on('permission-updated', () => {
      setTimeout(() => {
        this.refetchPermission()
      }, 1000)
    })

    // RTC Events
    this.$chatService.on(ChatEvents.RoomTextMessageReceivedEvent, data => {
      const playSound = data.message.type !== 'MEDIACALL' && data.message.type !== 'GROUPACTION'
      if (data.sender === this.self.uuid) {
        EventBus.$emit('message-sent', data)
      } else {
        if (playSound) {
          if (!this.messageTone) {
            this.messageTone = new Audio(message)
          }
          this.messageTone.play()
        }
        EventBus.$emit('message-received', data)
      }
    })

    this.$chatService.on(ChatEvents.RoomTextMessageDeliveredEvent, data => {
      if (data.messageType !== 'MEDIACALL' && data.messageType !== 'GROUPACTION') this.playDeliveredTone()
      EventBus.$emit('message-delivered', data)
    })

    this.$chatService.on(ChatEvents.RoomTextMessageReactedEvent, data => {
      const reactData = {
        ...data,
        type: 'react',
      }
      EventBus.$emit('message-received', reactData)
      this.playReactTone()
    })

    // Stop incoming ringtone when the user accepts the call
    EventBus.$on('user-on-call', () => {
      this.stopRinging()
      // hide call info modal for late joiners
      if (this.showCallModal) this.showCallModal = false
    })

    // When user leaves room
    this.$chatService.on(ChatEvents.RoomLeftEvent, data => {
      this.$store.dispatch('project/removeUserFromRoom', data)
      EventBus.$emit('room-left', data)
    })

    // When user is kicked out
    this.$chatService.on(ChatEvents.RoomKickedOutEvent, data => {
      this.$store.dispatch('project/removeUserFromRoom', data)
      EventBus.$emit('participant-kicked', data)
    })

    // When the group is deleted
    this.$chatService.on(ChatEvents.RoomDeletedEvent, data => {
      const payload = {
        roomUid: data.roomUid,
      }
      this.$store.dispatch('project/removeRoom', payload)
      EventBus.$emit('group-deleted', data.roomUid)
    })

    // When the group is renamed
    this.$chatService.on(ChatEvents.RoomRenamedEvent, data => {
      const payload = {
        roomUid: data.roomUid,
        name: data.name,
      }
      this.$store.dispatch('project/renameRoom', payload)
      EventBus.$emit('group-renamed', payload)
    })

    // When new participant is added
    this.$chatService.on(ChatEvents.RoomParticipantAddedEvent, data => {
      this.$store.dispatch('project/updateUsersInRoom', {
        roomUid: data.roomUid,
        name: data.name,
        participants: data.participants.filter(uuid => uuid !== this.self.uuid),
      })
      EventBus.$emit('participant-added', data)
    })

    // Set roomUid for the contacts
    this.$chatService.on(ChatEvents.RoomJoinedEvent, async ({ lastMessage, ...data }) => {
      // Handle rooms and chats
      if (!this.PROJECT_USERS.length && this.SELECTED_PROJECT) this.getProjectUsers()
      if (!this.SELECTED_PROJECT || !this.PROJECT_USERS.length) {
        this.$store.dispatch('chat/pushRoom', data)
        this.$store.dispatch('chat/pushInitialChat', data)
      } else if (data.participants.length) {
        // Find if the room already exists for the chat
        const oldRoom = this.$store.state.project.projectGroups.find(room => room.roomUid === data.roomUid)
        this.setRoomUidForPeer(data, 'room-joined')
        this.$nextTick(() => {
          if (data.length) {
            this.$store.dispatch('chat/setInitialChatLoad', data)
          } else {
            // This is a hack when the user is added to the old group
            // The old client doesn't have the oldGroup, hence the new data is loaded
            // eslint-disable-next-line no-lonely-if
            if (!oldRoom) { this.$store.dispatch('chat/setInitialChatLoad', [data]) }
          }
        })
      }

      // Handle media calls
      if (data.mediaState && Object.values(data.mediaState).length) {
        if (this.PROJECT_CONTACTS.length) this.handleOngoingCall(data.mediaState, data.roomUid)
        else {
          this.ongoingCall = {
            mediaState: data.mediaState,
            roomUid: data.roomUid,
          }
        }
      }

      // Handle unseen messages
      const unseenMsgs = data.unseen.find(msg => msg.userUid === this.self.uuid && Number(msg.unseenCount))
      if (unseenMsgs) {
        const payload = {
          roomUid: data.roomUid,
          unseenCount: Number(unseenMsgs.unseenCount),
        }
        this.$store.dispatch('chat/storeUnseenMsgCount', payload)
      }
    })

    // Set roomUid for the new peer
    this.$chatService.on(ChatEvents.RoomResolvedEvent, async data => this.setRoomUidForPeer(data, 'room-resolved'))

    // Listen to the incoming call and get the caller info
    this.$chatService.on(ChatEvents.CallRinging, data => {
      if (!this.$store.state.project.ongoingCall && this.self.uuid !== data.initiator) {
        this.caller = this.PROJECT_CONTACTS.find(user => user.roomUid === data.roomUid)
        this.caller.callType = data.callType
        this.caller.initiator = data.initiator
        this.roomUid = this.caller.roomUid

        this.$nextTick(() => {
          this.showCallModal = true
          if (!this.ringTone) {
            this.ringTone = new Audio(ringtone)
          }
          this.ringTone.loop = true
          this.ringTone.play()
        })
      }

      // Hide the call info modal in all tabs, when the call is accepted
      this.$chatService.on(ChatEvents.CallEstablished, this.onCallEstablished)

      this.$chatService.on(ChatEvents.CallDeclinedByUser, this.onCallDeclinedByUser)
      this.$chatService.on(ChatEvents.CallDisconnected, this.onCallDisconnected)
    })
    this.$chatService.on(ChatEvents.CallLeftByUser, this.onCallLeftByUser)
  },
  methods: {
    pushChatPage() {
      if (this.$route.name === 'chat') return
      this.$router.push({ name: 'chat' })
    },
    handleOngoingCall(mediaState, roomUid) {
      if (mediaState.established) {
        this.$store.dispatch('project/setOngoingCall', roomUid)
      } else {
        this.caller = this.PROJECT_CONTACTS.find(user => user.roomUid === roomUid)
        this.caller.initiator = mediaState.initiator
        this.roomUid = this.caller.roomUid
        this.$nextTick(() => { this.showCallModal = true })
      }
    },
    playDeliveredTone() {
      if (!this.deliveredTone) {
        this.deliveredTone = new Audio(delivered)
      }
      this.deliveredTone.play()
    },
    playReactTone() {
      if (!this.reactTone) {
        this.reactTone = new Audio(react)
      }
      this.reactTone.play()
    },
    onCallEstablished(data) {
      this.$store.dispatch('project/setOngoingCall', data.room)
      if (data.sender === this.self.uuid) {
        this.$chatService.off(ChatEvents.CallEstablished, this.onCallEstablished)
        this.$nextTick(() => {
          this.showCallModal = false
        })
        EventBus.$emit('user-on-call')
      }
    },
    onCallDisconnected(data) {
      this.$chatService.off(ChatEvents.CallDisconnected, this.onCallDisconnected)
      if (this.ringTone) {
        this.stopRinging()
        const user = this.$store.state.project.projectUsers.find(projectUser => projectUser.uuid === data.sender)
        // Is the CallInfo Modal Showing??
        if (this.showCallModal) {
          this.$nextTick(() => {
            this.showCallModal = false
            this.showDangerMessage({ title: 'Missed Call', message: `You missed a call from ${user.name}` })
          })
        }
      }
    },
    stopRinging() {
      if (this.ringTone) {
        this.ringTone.pause()
        this.ringTone.currentTime = 0
      }
    },
    onCallDeclinedByUser(data) {
      const contact = this.PROJECT_CONTACTS.find(projectContact => projectContact.roomUid === data.room)
      if (data.peerUid === this.self.uuid) {
        this.showCallModal = false
        this.stopRinging()
      } else {
        if (!this.callDeclinedBy.includes(data.peerUid)) {
          this.callDeclinedBy.push(data.peerUid)
        }
        const peer = this.PROJECT_USERS.find(user => user.uuid === data.peerUid)
        if (!this.showCallModal) this.showDangerMessage({ title: 'Call Declined', message: `${peer.name} has declined the call` })

        if (contact.type !== 'group' || this.callDeclinedBy.length === contact.participants.length - 1) {
          this.endCall(data.roomUid)
          this.stopRinging()
          this.callDeclinedBy = []
          this.$chatService.off(ChatEvents.CallDeclinedByUser, this.onCallDeclinedByUser)
        }
      }
    },
    onCallLeftByUser(data) {
      this.$chatService.off(ChatEvents.CallDeclinedByUser, this.onCallDeclinedByUser)
      // if call left by self
      if (data.peerUid === this.self.uuid) {
        this.$store.dispatch('project/setOngoingCall', !data.participants || data.participants?.length < 2 ? null : data.room)
        this.callDeclinedBy = []
      // if call left by another user
      } else {
        const user = this.$store.state.project.projectUsers.find(projectUser => projectUser.uuid === data.peerUid)

        // Is the CallInfo Modal Showing??
        if (this.showCallModal) {
          this.$nextTick(() => {
            this.showCallModal = false
            this.stopRinging()
            this.showDangerMessage({ title: 'Missed Call', message: `You missed a call from ${user.name}` })
          })
        }

        // if everyone left the call, end the call
        if (data.participants?.length < 2) {
          this.endCall(data.roomUid)
          this.$store.dispatch('project/setOngoingCall', null)
        }
        // only show user left notification, if still on call
        if (data.participants?.find(participant => participant === this.self.uuid)) {
          this.showInfoMessage({ title: 'User Left', message: `${user.name} has left the call` })
        }
      }
    },
    endCall(roomUid) {
      // if there is an ongoing call and a user has not joined the call, set the ongoing call roomUid to null when the call ends
      if (this.$store.state.project.ongoingCall) {
        this.$store.dispatch('project/setOngoingCall', null)
      }

      // end the call
      this.$chatService.endMediaCall(roomUid)
      this.$chatService.off(ChatEvents.CallLeftByUser, this.onCallLeftByUser)
      EventBus.$emit('call-ended')
    },
    logout() {
      useApollo.auth.logout().finally(() => {
        this.unsubscribeTopics()
        this.emptyLocalStorageAndRedirectToLogin()
      })
    },
    fetchUserDetails(user = JSON.parse(localStorage.getItem('userData'))) {
      this.name = user.name
      this.avatar = user.avatar ? `${user.avatar}?r=${this.unixDate()}` : null
      this.isAdmin = user.isAdmin
    },
    emptyLocalStorageAndRedirectToLogin() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem('userData')
      localStorage.removeItem('cached-filters')
      localStorage.removeItem('selected_project')
      localStorage.clear()
      // eslint-disable-next-line no-restricted-globals
      location.href = '/login'
    },
    changeProject() {
      const keys = ['selected_project', 'selected_company', 'company', 'project', 'projectUserRole']
      keys.forEach(key => localStorage.removeItem(key))
      this.$store.dispatch('permissions/clearPermissions')
      this.$store.dispatch('project/STORE_SELECTED_PROJECT', null)
      this.$store.dispatch('project/STORE_PROJECT', null)
      this.$store.dispatch('project/RESET_PROJECT_COMPANY')
      this.$store.dispatch('project/STORE_PROJECT_USERS', [])
      this.$router.push({ name: 'select-project-roadblock' })
    },
    unsubscribeTopics() {
      const topics = TOPICS
      topics.forEach(topic => {
        unsubscribeToken(topic)
      })
    },
    refetchPermission() {
      if (this.SELECTED_PROJECT) {
        projectSettings().then(response => {
          this.$store.dispatch('project/setProjectSettings', {
            enableCloudService: response?.enableCloudService,
          })
        })
      }

      checkPermission(this.permissions, permissions => {
        const userPermissions = Object.entries(permissions).map(([key, value]) => ({ key: mapPermissionSlug[key], value }))
        this.$store.dispatch('permissions/setPermissions', userPermissions)
      })
    },
    getProjectUsers() {
      useApollo.users.getProjectUsers({
        projectUid: [this.$store.state.project.selectedProject],
      }).then(response => {
        const projectUsers = response.data.users.data.map(user => ({
          ...user,
          roomUid: null,
        }))
        this.$store.dispatch('project/STORE_PROJECT_USERS', projectUsers)
      }).finally(() => {
        this.$store.dispatch('chat/fetchChatsAndContacts')
      })
    },
    setRoomUidForPeer(data, action) {
      let peerUid
      // get the peer user uuid
      if (action === 'room-joined') {
        peerUid = data.participants.filter(participant => participant.uuid !== this.self.uuid)
          .map(participant => participant.uuid)
      } else {
        peerUid = data.participants.filter(participant => participant !== this.self.uuid)
      }
      // set roomUid in contact details
      this.$store.dispatch('project/updateProjectUser', { users: peerUid, data })
      this.$nextTick(() => {
        if (action === 'room-resolved') EventBus.$emit('room-resolved', data)
        else if (action === 'room-joined') EventBus.$emit('room-joined', data)
      })
    },
    visitTour() {
      EventBus.$emit('start-tour')
    },
  },
}
</script>
<style lang="scss">
.nav-workZone {
    .dropdown-menu.dropdown-menu-right.show {
      min-width: 15rem  !important;
    }
  }
</style>
<style scoped lang="scss">
  .show .btn svg {
    color: #f1f1f1;
  }
  .notifications ul {
    min-width: 300px;
  }
  .v-step{
    margin-top:30px;
  }
  .fa-chalkboard-teacher {
    font-size: 14px;
    margin-top: 4px;
  }
</style>
