import i18n from '@/libs/i18n'
import store from '@/store'
import settings from './settings'

const _t = path => i18n.t(`${path}`)

export default function (isAdmin = false) {
  const hasPermission = (slugs, value) => (store.getters['permissions/getPermissionsCount'](slugs) ? [value] : [])

  const isEnabled = (setting, value) => (store.getters['project/GET_PROJECT_SETTINGS'][setting] ? [value] : [])

  const isManager = localStorage.getItem('projectUserRole') === 'Project Manager'

  const commonMenu = [
    {
      title: _t('Home'),
      route: 'home',
      icon: 'HomeIcon',
    },
    {
      title: _t('Screenshots'),
      route: 'screen-casts',
      icon: 'VideoIcon',
    },
    {
      header: _t('Reports'),
      icon: 'HardDriveIcon',
      children: [
        {
          title: _t('Hours Tracked'),
          route: 'hours-tracked',
        },
        {
          title: _t('Weekly Time'),
          route: 'timeline',
        },
        {
          title: _t('Attendance'),
          route: 'attendance',
        },
        ...(isManager ? [{
          title: _t('Team Report'),
          route: 'team-report',
        },
        ] : []),
        {
          title: _t('Idle Time'),
          route: 'idle-time',
        },
        {
          title: _t('Manual Time'),
          route: 'manual-time',
        },
      ],
    },
    {
      header: _t('Payroll'),
      icon: 'BriefcaseIcon',
      children: [
        {
          title: _t('Payments'),
          route: 'payments',
        },
        {
          title: _t('Payment History'),
          route: 'payment-history',
        },
      ],
    },
    {
      header: _t('Tools'),
      icon: 'SmartphoneIcon',
      children: [
        {
          title: _t('Downloads'),
          route: 'downloads',
        },
        {
          title: _t('Calculator'),
          route: 'calculator',
        },
        ...(hasPermission(['campaigns.create'], {
          title: _t('Social Media Campaign'),
          route: 'social-media-campaign',
        })),
        ...(hasPermission(['cms.update'], {
          title: _t('CMS'),
          route: 'cms',
        })),
        ...(isEnabled('enableCloudService', {
          title: _t('File Manager (Cloud)'),
          route: 'file-manager',
        })),
      ],
    },
    ...(hasPermission(['leads.create', 'leads.close', 'leads.follow'], {
      title: _t('CRM'),
      route: 'crm',
      icon: 'UsersIcon',
    })),
    {
      title: _t('Accounts'),
      route: 'wallet',
      icon: 'CreditCardIcon',
    },
    settings(isAdmin, isManager),
  ]
  return commonMenu
}
